import React from "react";
import vid from '../../../assets/video/vid.mov';

const MissionComponent = () => {
  const containerStyle = {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#f8f8f8",
  };

  const headingStyle = {
    fontSize: "2.5em",
    marginBottom: "20px",
    color: "#333",
  };

  const paragraphStyle = {
    fontSize: "1.2em",
    color: "#666",
    marginBottom: "40px",
    width: "80%",
    margin: "0 auto",
  };

  const videoContainerStyle = {
    width: "100%",
    maxWidth: "1000px",
    margin: "0 auto",
  };

  const videoStyle = {
    width: "100%",
    height: "auto",
  };

  return (
    <div style={containerStyle}>
      {/* Subheading and Description */}
      <div>
        <h2 style={headingStyle}>Our Mission</h2>
        <p style={paragraphStyle}>
          Saumic Craft is committed to the economic empowerment of Rajasthani
          artists. By providing them with a platform to showcase their talents,
          we aim to preserve the rich cultural heritage of our region while
          fostering a sustainable future for our artisans.
        </p>
      </div>

      {/* Video Section */}
      <div style={videoContainerStyle}>
        {/* Video Tag with Autoplay, Loop, and Muted */}
        <video style={videoStyle} autoPlay muted loop>
          <source src={vid} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default MissionComponent;
