import React from 'react';
import { Card } from 'antd'; // Import Ant Design Card
import './VideoGallery.css';  // Custom CSS for layout and styling

const VideoGallery = () => {
  const videos = [
    {
      videoUrl: "https://drive.google.com/file/d/1t9um4ww__OBNaqXhy-OUQeM8yMzSeZ5X/preview",
      title: "Video Title 1",
      description: "This is a description uhfusdufhudshfuhudshfjdshfhsdjfhdshufsdhfkjshjdshfkjhsdjkfhjdhsfkhsdjkof video 1.",
    },
    {
      videoUrl: "https://drive.google.com/file/d/1XYEh7GDEMzAikj2GDdlIaczoRl1ZxTvg/preview",
      title: "Video Title 2",
      description: "This is a description of video 2.",
    },
    {
      videoUrl: "https://drive.google.com/file/d/1iNgIwzfrjtg2SZ6PqQkctgjyjvZYM_IG/preview",
      title: "Video Title 3",
      description: "This is a description of video 3.",
    },
    {
      videoUrl: "https://drive.google.com/file/d/1-Awl0rpWSK3lJZRX1_LjCP0rjDrv562r/preview",
      title: "Video Title 4",
      description: "This is a description of video 4.",
    },
    {
      videoUrl: "https://drive.google.com/file/d/1j5dSmFvDNhChzDrTibOxGOufBwOhOGQf/preview",
      title: "Video Title 5",
      description: "This is a description of video 5.",
    },
  ];

  return (
    <Card bordered={true} className="video-gallery-card" style={{paddingTop:'10rem'}} name="ourprocedure">
      <div className="video-gallery">
        <div className="videos-column">
          {videos.map((video, index) => (
            <iframe
              key={index}
              src={video.videoUrl}
              width="100%"
              height="300px"  // Increased the height of videos
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={`Video ${index + 1}`}
              className="video-frame"
            ></iframe>
          ))}
        </div>
        <div className="info-column">
          {videos.map((video, index) => (
            <div key={index} className="video-info">
              <h2>{video.title}</h2>
              <p>{video.description}</p>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default VideoGallery;
