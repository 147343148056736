import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Import your tab components
import TabOne from './TabOne/TabOne';
import TabTwo from './TabTwo/TabTwo';
import TabThree from './TabThree/TabThree';
import TabFour from './TabFour/TabFour';

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('TabOne'); // Set default tab
  const navigate = useNavigate();

  // Handle logout
  const handleLogout = () => {
    localStorage.clear(); // Clear all localStorage
    navigate('/'); // Redirect to homepage
  };

  // Tab Components
  const renderTabContent = () => {
    switch (activeTab) {
      case 'TabOne':
        return <TabOne />;
      case 'TabTwo':
        return <TabTwo />;
      case 'TabThree':
        return <TabThree />;
      case 'TabFour':
        return <TabFour />;
      default:
        return <TabOne />;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-800 to-gray-900 text-white relative p-1 m-0">
      {/* Logout Button */}
      <button
        className="absolute top-5 right-5 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md transition-all duration-300"
        onClick={handleLogout}
      >
        Logout
      </button>

      {/* Tabs (Dock Style) */}
      <div className="flex justify-center mt-10">
        <div className="flex space-x-6 bg-white/10 p-4 rounded-full shadow-lg backdrop-blur-lg">
          <button
            className={`text-lg font-semibold px-4 py-2 rounded-full shadow-xl transition-all duration-300 transform hover:scale-110 hover:bg-gradient-to-r from-blue-400 to-blue-600 ${activeTab === 'TabOne' ? 'bg-blue-500' : 'bg-white/20'}`}
            onClick={() => setActiveTab('TabOne')}
          >
            Webinar
          </button>
          <button
            className={`text-lg font-semibold px-4 py-2 rounded-full shadow-xl transition-all duration-300 transform hover:scale-110 hover:bg-gradient-to-r from-green-400 to-green-600 ${activeTab === 'TabTwo' ? 'bg-blue-500' : 'bg-white/20'}`}
            onClick={() => setActiveTab('TabTwo')}
          >
            Blogs
          </button>
          <button
            className={`text-lg font-semibold px-4 py-2 rounded-full shadow-xl transition-all duration-300 transform hover:scale-110 hover:bg-gradient-to-r from-red-400 to-red-600 ${activeTab === 'TabThree' ? 'bg-blue-500' : 'bg-white/20'}`}
            onClick={() => setActiveTab('TabThree')}
          >
            Products
          </button>
          {/* <button
            className={`text-lg font-semibold px-4 py-2 rounded-full shadow-xl transition-all duration-300 transform hover:scale-110 hover:bg-gradient-to-r from-purple-400 to-purple-600 ${activeTab === 'TabFour' ? 'bg-blue-500' : 'bg-white/20'}`}
            onClick={() => setActiveTab('TabFour')}
          >
            Tab Four
          </button> */}
        </div>
      </div>

      {/* Tab Content */}
      <div className="mt-12 p-6">{renderTabContent()}</div>
    </div>
  );
};

export default Dashboard;
