// import React from "react";
// import { Layout, Row, Col, Typography, Space, Button } from "antd";
// import { InstagramOutlined, FacebookOutlined, MailOutlined } from "@ant-design/icons";
// import { Link } from "react-scroll";
// // Assets
// import Logo from "../../assets/logo.png";

// const { Footer } = Layout;
// const { Title, Text } = Typography;

// export default function Contact() {
//   const getCurrentYear = () => {
//     return new Date().getFullYear();
//   };

//   return (
//     <Footer style={{ backgroundColor: "#ffffff", padding: "40px 0" }}>
//       <div className="container">
//         <Row align="middle" justify="space-between" gutter={[16, 32]}>
//           <Col xs={24} sm={12} md={8}>
//             <Space size="middle">
//               <Link to="home" smooth={true} offset={-80} className="pointer">
//                 <img src={Logo} width="200px" alt="Logo" />
//               </Link>
//             </Space>
//           </Col>

//           <Col xs={24} sm={12} md={8}>
//             <Title level={4} style={{ color: "#333", textAlign: "center" }}>Connect with Us</Title>
//             <Space direction="vertical" size="middle" style={{ width: "100%", textAlign: "center" }}>
//               <Button
//                 type="link"
//                 href="https://instagram.com"
//                 target="_blank"
//                 icon={<InstagramOutlined />}
//                 style={{ color: "#ff7f50", fontSize: "16px" }}
//               >
//                 Instagram
//               </Button>
//               <Button
//                 type="link"
//                 href="https://facebook.com"
//                 target="_blank"
//                 icon={<FacebookOutlined />}
//                 style={{ color: "#ff7f50", fontSize: "16px" }}
//               >
//                 Facebook
//               </Button>
//               <Button
//                 type="link"
//                 icon={<MailOutlined />}
//                 style={{ color: "#ff7f50", fontSize: "16px" }}
//               >
//                 Contact Us
//               </Button>
//             </Space>
//           </Col>

//           <Col xs={24} sm={24} md={8}>
//             <Title level={4} style={{ color: "#333", textAlign: "center" }}>Newsletter</Title>
//             <Space direction="vertical" style={{ width: "100%", textAlign: "center" }}>
//               <Text style={{ fontSize: "16px" }}>Subscribe to our newsletter</Text>
//               <div style={{ display: "flex", marginTop: "8px", justifyContent: "center" }}>
//                 <input
//                   type="email"
//                   placeholder="Enter your email"
//                   style={{
//                     padding: "10px",
//                     fontSize: "16px",
//                     border: "1px solid #ddd",
//                     borderRadius: "4px 0 0 4px",
//                     outline: "none",
//                     width: "70%",
//                     maxWidth: "300px"
//                   }}
//                 />
//                 <button
//                   style={{
//                     padding: "10px 20px",
//                     fontSize: "16px",
//                     backgroundColor: "#ff7f50",
//                     border: "none",
//                     borderRadius: "0 4px 4px 0",
//                     color: "#fff",
//                     cursor: "pointer",
//                   }}
//                 >
//                   Subscribe
//                 </button>
//               </div>
//             </Space>
//           </Col>
//         </Row>

//         <Row justify="center" style={{ marginTop: "20px", textAlign: "center" }}>
//           <Text type="secondary" style={{ fontSize: "14px" }}>
//             © {getCurrentYear()} Saumic Craft. All Rights Reserved.
//           </Text>
//         </Row>
//       </div>
//     </Footer>
//   );
// }




import React from "react";
import { Layout, Row, Col, Typography, Space } from "antd";
import { InstagramOutlined, FacebookOutlined, MailOutlined } from "@ant-design/icons";
import { Link } from "react-scroll";
// Assets
import Logo from "../../assets/logo.png";

const { Footer } = Layout;
const { Title, Text } = Typography;

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Footer style={{
      background: "linear-gradient(135deg, #2b2b2b 0%, #1f1f1f 100%)",
      padding: "40px 0",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      color: "#ffffff",
    }}>
      <div className="container" style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 20px" }}>
        <Row align="middle" justify="space-between" gutter={[16, 32]}>
          <Col xs={24} sm={12} md={8}>
            <Space size="middle">
              <Link to="home" smooth={true} offset={-80} className="pointer">
                <img src={Logo} width="200px" alt="Logo" />
              </Link>
            </Space>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Title level={4} style={{ color: "#ffffff", textAlign: "center", marginBottom: "20px" }}>Connect with Us</Title>
            <Space direction="vertical" size="middle" style={{ width: "100%", textAlign: "center" }}>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#ffffff",
                  fontSize: "16px",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  transition: "color 0.3s ease, text-shadow 0.3s ease",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.color = "#d4d4d4";
                  e.currentTarget.style.textShadow = "0 0 5px #fff, 0 0 10px #fff";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.color = "#ffffff";
                  e.currentTarget.style.textShadow = "none";
                }}
              >
                <InstagramOutlined style={{ fontSize: "20px", marginRight: "8px" }} />
                Instagram
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#ffffff",
                  fontSize: "16px",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  transition: "color 0.3s ease, text-shadow 0.3s ease",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.color = "#d4d4d4";
                  e.currentTarget.style.textShadow = "0 0 5px #fff, 0 0 10px #fff";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.color = "#ffffff";
                  e.currentTarget.style.textShadow = "none";
                }}
              >
                <FacebookOutlined style={{ fontSize: "20px", marginRight: "8px" }} />
                Facebook
              </a>
              <a
                href="mailto:contact@yourdomain.com"
                style={{
                  color: "#ffffff",
                  fontSize: "16px",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  transition: "color 0.3s ease, text-shadow 0.3s ease",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.color = "#d4d4d4";
                  e.currentTarget.style.textShadow = "0 0 5px #fff, 0 0 10px #fff";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.color = "#ffffff";
                  e.currentTarget.style.textShadow = "none";
                }}
              >
                <MailOutlined style={{ fontSize: "20px", marginRight: "8px" }} />
                Contact Us
              </a>
            </Space>
          </Col>

          <Col xs={24} sm={24} md={8}>
            <Title level={4} style={{ color: "#ffffff", textAlign: "center", marginBottom: "20px" }}>Newsletter</Title>
            <Space direction="vertical" style={{ width: "100%", textAlign: "center" }}>
              <Text style={{ fontSize: "16px", marginBottom: "10px", color: "#ffffff" }}>Subscribe to our newsletter</Text>
              <div style={{ display: "flex", marginTop: "8px", justifyContent: "center" }}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  style={{
                    padding: "10px",
                    fontSize: "16px",
                    border: "1px solid #444",
                    borderRadius: "4px 0 0 4px",
                    outline: "none",
                    width: "70%",
                    maxWidth: "300px",
                    color: "#ffffff",
                    backgroundColor: "#333",
                  }}
                />
                <button
                  style={{
                    padding: "10px 20px",
                    fontSize: "16px",
                    background: "linear-gradient(45deg, #333, #666)",
                    border: "none",
                    borderRadius: "0 4px 4px 0",
                    color: "#ffffff",
                    cursor: "pointer",
                    fontWeight: "bold",
                    transition: "background 0.5s ease, transform 0.3s ease",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.background = "linear-gradient(45deg, #666, #333)";
                    e.currentTarget.style.transform = "scale(1.05)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.background = "linear-gradient(45deg, #333, #666)";
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  Subscribe
                </button>
              </div>
            </Space>
          </Col>
        </Row>

        <Row justify="center" style={{ marginTop: "45px", textAlign: "center" }}>
          <Text type="secondary" style={{ fontSize: "14px", color: "#ffffff" }}>
            © {getCurrentYear()} Saumic Craft. All Rights Reserved.
          </Text>
        </Row>
      </div>
    </Footer>
  );
}
