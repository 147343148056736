import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message } from 'antd';
import axios from 'axios';
import UploadImage from './UploadImage';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const TabTwo = () => {
  const [blogs, setBlogs] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentBlog, setCurrentBlog] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/blogs`);
      setBlogs(response.data);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  const handleAddClick = () => {
    setEditMode(false);
    setIsModalVisible(true);
    form.resetFields();
    setImageUrl('');
  };

  const handleEditClick = (blog) => {
    setEditMode(true);
    setCurrentBlog(blog);
    form.setFieldsValue(blog);
    setImageUrl(blog.image || '');
    setIsModalVisible(true);
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${apiUrl}/api/blogs/${id}`);
      message.success('Blog deleted successfully');
      fetchBlogs();
    } catch (error) {
      message.error('Error deleting blog');
    }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const blogData = { ...values, image: imageUrl }; // Include the image URL
  
      if (editMode) {
        await axios.put(`${apiUrl}/api/blogs/${currentBlog._id}`, blogData);
        message.success('Blog updated successfully');
      } else {
        await axios.post(`${apiUrl}/api/blogs`, blogData);
        message.success('Blog added successfully');
      }
      fetchBlogs();
      setIsModalVisible(false);
    } catch (error) {
      message.error('Error saving blog');
    }
  };
  

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    { title: 'Serial', render: (_, __, index) => index + 1 },
    { title: 'Title', dataIndex: 'title', key: 'title' },
    { title: 'Content', dataIndex: 'content', key: 'content' },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image) =>
        image ? <img src={`${apiUrl}${image}`} alt="blog" width="100" /> : 'No Image',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div>
          <Button type="link" onClick={() => handleEditClick(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDeleteClick(record._id)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6 bg-gray-700 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">Manage Blogs</h1>
      <Button type="primary" onClick={handleAddClick} className="mb-4">
        Add Blog
      </Button>
      <Table
        columns={columns}
        dataSource={blogs}
        rowKey="_id"
        pagination={{ pageSize: 5 }}
      />
      <Modal
        title={editMode ? 'Edit Blog' : 'Add Blog'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={editMode ? 'Update' : 'Add'}
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please enter the title!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="content"
            label="Content"
            rules={[{ required: true, message: 'Please enter the content!' }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Upload Image">
            <UploadImage onUpload={setImageUrl} initialImageUrl={imageUrl} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TabTwo;