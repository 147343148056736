import React, { useState } from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const UploadImage = ({ onUpload, initialImageUrl }) => {
  const [fileList, setFileList] = useState([]);
  const [previewUrl, setPreviewUrl] = useState(initialImageUrl || '');

  const handleUpload = async ({ file }) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(`${apiUrl}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      message.success('Image uploaded successfully');
      const imageUrl = response.data.imageUrl;
      setPreviewUrl(imageUrl);
      onUpload(imageUrl); // Pass the uploaded image URL back to the parent component
    } catch (error) {
      message.error('Failed to upload image');
    }
  };

  return (
    <div>
      {previewUrl && (
        <img src={`${apiUrl}${previewUrl}`} alt="Preview" style={{ maxWidth: '100%', marginBottom: '10px' }} />
      )}
      <Upload
        beforeUpload={() => false}
        fileList={fileList}
        onChange={({ fileList }) => setFileList(fileList.slice(-1))} // Only keep one file in the list
        customRequest={handleUpload} // Custom request for upload handling
        listType="picture"
      >
        <Button icon={<UploadOutlined />}>Upload Image</Button>
      </Upload>
    </div>
  );
};

export default UploadImage;
