import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Card, Input, Button } from "antd";

import first from "../../../assets/img/first.jpg";
import Second from "../../../assets/img/Second.png";
import Third from "../../../assets/img/Third.jpg";

import Footer from "../Footer";
import Whatsapp from "../../Whatsapp";

export default function Contact() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <>
    <CardWrapper style={{ paddingTop: '5rem' }}>
      <StyledCard bordered={true}>
        <div className="lightBg">
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">Let's get in touch</h1>
              <p className="font13">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                <br />
                labore et dolore magna aliquyam erat, sed diam voluptua.
              </p>
            </HeaderInfo>
            <div className="row" style={{ paddingBottom: "30px" }}>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Form>
                  <label className="font13">First name:</label>
                  <StyledInput placeholder="Enter your first name" />
                  <label className="font13">Email:</label>
                  <StyledInput placeholder="Enter your email" />
                  <label className="font13">Subject:</label>
                  <StyledInput placeholder="Enter subject" />
                  <StyledTextArea rows={4} placeholder="Enter your message" />
                </Form>
                <SubmitWrapper className="flex">
                  <StyledButton type="primary" className="pointer animate radius8">
                    Send Message
                  </StyledButton>
                </SubmitWrapper>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
                <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                  <ContactImgBox isVisible={isVisible}>
                    <img src={first} alt="office" className="radius6" />
                  </ContactImgBox>
                  <ContactImgBox isVisible={isVisible}>
                    <img src={Second} alt="office" className="radius6" />
                  </ContactImgBox>
                </div>
                <div style={{ width: "50%" }}>
                  <div style={{ marginTop: "100px" }}>
                    <ContactImgBox isVisible={isVisible}>
                      <img src={Third} alt="office" className="radius6" />
                    </ContactImgBox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </CardWrapper>
    <Footer/>
    <Whatsapp/>
    </>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CardWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCard = styled(Card)`
  border: 2px solid orange;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 860px) {
    width: 100%;
  }
  @media (min-width: 861px) and (max-width: 1200px) {
    width: 80%;
  }
  @media (min-width: 1201px) {
    width: 60%;
  }
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Form = styled.form`
  padding: 70px 0 30px 0;
`;

const StyledInput = styled(Input)`
  margin-bottom: 20px;
  border-bottom: 2px solid orange;
`;

const StyledTextArea = styled(Input.TextArea)`
  margin-bottom: 20px;
  border-bottom: 2px solid orange;
`;

const StyledButton = styled(Button)`
  background-color: orange;
  border-color: orange;
  color: #fff;

  &:hover {
    background-color: #ff5733;
    border-color: #ff5733;
    color: #fff;
  }
`;

const ContactImgBox = styled.div`
  max-width: 180px;
  align-self: flex-end;
  margin: 10px 30px 10px 0;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  animation: ${(props) => (props.isVisible ? fadeIn : 'none')} 1s ease-in;
`;

const SubmitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;





// import React, { useEffect, useState } from "react";
// import styled, { keyframes } from "styled-components";
// import { Card, Input, Button } from "antd";

// import first from "../../../assets/img/first.jpg";
// import Second from "../../../assets/img/Second.png";
// import Third from "../../../assets/img/Third.jpg";

// export default function Contact() {
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     setIsVisible(true);
//   }, []);

//   return (
//     <CardWrapper style={{ paddingTop: '5rem' }}>
//       <StyledCard bordered={true}>
//         <div className="lightBg">
//           <div className="container">
//             <HeaderInfo>
//               <h1 className="font40 extraBold">Let's get in touch</h1>
//               <p className="font13">
//                 Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
//                 <br />
//                 labore et dolore magna aliquyam erat, sed diam voluptua.
//               </p>
//             </HeaderInfo>
//             <div className="row" style={{ paddingBottom: "30px" }}>
//               <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
//                 <Form>
//                   <label className="font13">First name:</label>
//                   <Input type="text" id="fname" name="fname" className="font20 extraBold" />
//                   <label className="font13">Email:</label>
//                   <Input type="email" id="email" name="email" className="font20 extraBold" />
//                   <label className="font13">Subject:</label>
//                   <Input type="text" id="subject" name="subject" className="font20 extraBold" />
//                   <Input.TextArea rows={4} id="message" name="message" className="font20 extraBold" />
//                 </Form>
//                 <SubmitWrapper className="flex">
//                   <StyledButton type="primary" className="pointer animate radius8">Send Message</StyledButton>
//                 </SubmitWrapper>
//               </div>
//               <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
//                 <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
//                   <ContactImgBox isVisible={isVisible}>
//                     <img src={first} alt="office" className="radius6" />
//                   </ContactImgBox>
//                   <ContactImgBox isVisible={isVisible}>
//                     <img src={Second} alt="office" className="radius6" />
//                   </ContactImgBox>
//                 </div>
//                 <div style={{ width: "50%" }}>
//                   <div style={{ marginTop: "100px" }}>
//                     <ContactImgBox isVisible={isVisible}>
//                       <img src={Third} alt="office" className="radius6" />
//                     </ContactImgBox>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </StyledCard>
//     </CardWrapper>
//   );
// }

// const fadeIn = keyframes`
//   from { opacity: 0; }
//   to { opacity: 1; }
// `;

// const CardWrapper = styled.div`
//   padding: 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const StyledCard = styled(Card)`
//   border: 2px solid orange;
//   border-radius: 10px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

//   @media (max-width: 860px) {
//     width: 100%;
//   }
//   @media (min-width: 861px) and (max-width: 1200px) {
//     width: 80%;
//   }
//   @media (min-width: 1201px) {
//     width: 60%;
//   }
// `;

// const HeaderInfo = styled.div`
//   padding: 70px 0 30px 0;
//   @media (max-width: 860px) {
//     text-align: center;
//   }
// `;

// const Form = styled.form`
//   padding: 70px 0 30px 0;
// `;

// const StyledButton = styled(Button)`
//   width: 100%;
//   padding: 15px;
//   background-color: orange;
//   border-color: orange;
//   color: #fff;

//   &:hover {
//     background-color: #ff5733;
//     border-color: #ff5733;
//   }
// `;

// const ContactImgBox = styled.div`
//   max-width: 180px;
//   align-self: flex-end;
//   margin: 10px 30px 10px 0;
//   opacity: 0;
//   animation: ${fadeIn} 1s forwards;
//   animation-delay: ${({ isVisible }) => (isVisible ? "0.5s" : "0s")};

//   img {
//     width: 100%;
//     border-radius: 6px;
//     transition: filter 0.3s ease-in-out;

//     &:hover {
//       filter: hue-rotate(150deg) brightness(80%);
//     }
//   }
// `;

// const SubmitWrapper = styled.div`
//   @media (max-width: 991px) {
//     width: 100%;
//     margin-bottom: 50px;
//   }
// `;
