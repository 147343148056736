import React from 'react';
import cover from '../../../assets/cover.jpg'; // Path to your uploaded image
import amaz from '../../../assets/amaz.png'; // Placeholder for button image

const HeroSection = () => {
  return (
    <div className="relative w-full">
      <img
        src={cover}
        alt="Cover"
        className="w-full h-full object-cover"
      />

      {/* Heading */}
      <div className="absolute top-12 left-8 text-white">
        <h1 className="text-7xl font-bold leading-tight" style={{ fontFamily: 'Times New Roman', marginLeft: '6.5rem', marginTop: '2.5rem' }}>
          Start Your Online<br />Business Today
        </h1>
      </div>

      {/* Buttons */}
      <div className="absolute bottom-12 left-8 flex flex-row space-x-10" style={{ marginLeft: '6.5rem', marginBottom: '3rem' }}>
        
        {/* Learn More Button */}
        <button
          className="w-[150px] h-[50px] bg-no-repeat bg-center bg-contain transition-all transform hover:scale-110 hover:shadow-9xl hover:bg-opacity-50 hover:backdrop-blur-md hover:rounded-lg p-2 border border-white shadow-lg"
          style={{
            backgroundImage: `url(${amaz})`,
            backgroundSize: '80%',
            backgroundPosition: 'center',
            borderRadius: '12px',
            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Transparent background
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 15px rgba(0, 0, 0, 0.2)', // Deeper shadow for 3D effect
            transition: 'box-shadow 0.3s ease, transform 0.3s ease',
            backdropFilter: 'blur(5px)', // Adds the blur behind the button
          }}
        >
          <span className="sr-only">Learn More</span>
        </button>

        {/* Sign Up Button */}
        <button
          className="w-[150px] h-[50px] bg-no-repeat bg-center bg-contain transition-all transform hover:scale-110 hover:shadow-9xl hover:bg-opacity-50 hover:backdrop-blur-md hover:rounded-lg p-2 border border-white shadow-lg"
          style={{
            backgroundImage: `url(${amaz})`,
            backgroundSize: '80%',
            backgroundPosition: 'center',
            borderRadius: '12px',
            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Transparent background
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 15px rgba(0, 0, 0, 0.2)',
            transition: 'box-shadow 0.3s ease, transform 0.3s ease',
            backdropFilter: 'blur(5px)',
          }}
        >
          <span className="sr-only">Sign Up</span>
        </button>

        {/* Contact Us Button */}
        <button
          className="w-[150px] h-[50px] bg-no-repeat bg-center bg-contain transition-all transform hover:scale-110 hover:shadow-9xl hover:bg-opacity-50 hover:backdrop-blur-md hover:rounded-lg p-2 border border-white shadow-lg"
          style={{
            backgroundImage: `url(${amaz})`,
            backgroundSize: '80%',
            backgroundPosition: 'center',
            borderRadius: '12px',
            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Transparent background
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 15px rgba(0, 0, 0, 0.2)',
            transition: 'box-shadow 0.3s ease, transform 0.3s ease',
            backdropFilter: 'blur(5px)',
          }}
        >
          <span className="sr-only">Contact Us</span>
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
