import React from 'react';
import { Card } from 'antd';
import back from '../../../assets/video/back.mov';

const VideoWithButton = () => {
  return (
    <div className="relative flex items-center justify-center min-h-screen bg-gradient-to-r from-gray-100 to-gray-300 p-4">
      {/* Card containing video */}
      <Card
        className="relative w-full md:w-3/4 lg:w-2/3 shadow-2xl rounded-3xl overflow-hidden transform transition-transform duration-500 hover:scale-105 hover:shadow-3xl"
        hoverable
        bordered={false}
        style={{ padding: 0 }} // Ensure no extra padding
      >
        {/* Background Video */}
        <video
          autoPlay
          loop
          muted
          className="w-full h-auto object-cover transform transition-transform duration-500 hover:scale-110"
        >
          <source src={back} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Card>

      {/* Button at the bottom */}
      <div className="absolute bottom-10 w-full flex justify-center">
        <button className="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-semibold py-4 px-12 rounded-full shadow-lg transition duration-300 transform hover:scale-105">
          Enroll Now
        </button>
      </div>
    </div>
  );
};

export default VideoWithButton;
